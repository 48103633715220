.background {
}

.background::after {
  content: '';
  background-image: url('../assets/background.jpg');
  opacity: 0.9;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: absolute;
  z-index: -1;
}
