@tailwind base;
@tailwind components;
@tailwind utilities;
@import '~react-toastify/dist/ReactToastify.css';
@import '~react-credit-cards/es/styles-compiled.css';
body {
  width: 100%;
  height: 100%;
}

/* Make a custom scrollbar */
::-webkit-scrollbar {
  height: 8px;
  width: 8px;
}

::-webkit-scrollbar-thumb {
  background: linear-gradient(transparent, #4f46e5);
  border-radius: 3px;
}

::-webkit-scrollbar-thumb:hover {
  background: linear-gradient(transparent, #00c6ff);
}
